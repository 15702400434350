/**
 * @generated SignedSource<<ada7a610a2fa7225a2eae77af6b1b505>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type latestDiscoveryWidgetQueue$data = {
  readonly items: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly entityId?: string;
        readonly " $fragmentSpreads": FragmentRefs<"homeSecondaryContentItemContent">;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "latestDiscoveryWidgetQueue";
};
export type latestDiscoveryWidgetQueue$key = {
  readonly " $data"?: latestDiscoveryWidgetQueue$data;
  readonly " $fragmentSpreads": FragmentRefs<"latestDiscoveryWidgetQueue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "latestDiscoveryWidgetQueue",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 6
        }
      ],
      "concreteType": "QueueItemConnection",
      "kind": "LinkedField",
      "name": "items",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QueueItemsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "entityId",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "homeSecondaryContentItemContent"
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "items(first:6)"
    }
  ],
  "type": "Queue",
  "abstractKey": null
};

(node as any).hash = "3b32176e64ff4489f6ad3582905aaf71";

export default node;
